import { useState, useMemo, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ButtonBasic, InputSearchIdCompleto, SelectIvaSoftland, SelectLocalidadesSoftland, SelectLugaresCalendar } from '../../components';
import ApiSoftland from '../../services/apis.softland.service';
import {toast} from 'react-toastify';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import TratoService from '../../services/tratos.service';

const ModalEditarPreServicio = ({show, hideModal, item, onGuardar}) => {
    const handleClose = () => hideModal();
    const [isLoading, setIsLoading] = useState(false);
    const [nombre, setNombre] = useState(null);
    const [localidad, setLocalidad] = useState(null);
    const [codPostal, setCodPostal] = useState(null);
    const [barrio, setBarrio] = useState(null);
    const [calle, setCalle] = useState('');
    const [latitud, setLatitud] = useState(null);
    const [longitud, setLongitud] = useState(null);
    const onSubmit = async() => {
        const form = {
            id_t: item?.id_t,
            nombre_t: nombre?.toUpperCase(),
            lugarId_t: localidad,
            codpostal_t: codPostal,
            barrio_t: barrio?.toUpperCase(),
            calle_t: calle?.toUpperCase(),
        };
        setIsLoading(true);
        if(latitud?.length > 0 || longitud?.length > 0){
            const validCoord = `${latitud?.trim()}, ${longitud?.trim()}`
            const valid = AuxiliarFunction.isValidCoordinates(validCoord)
            if(valid){
                form.latitud_t = latitud?.trim();
                form.longitud_t = longitud?.trim();
                await TratoService.changePreServicio(form).then(()=>{
                    setIsLoading(false);
                    handleClose()
                    if(onGuardar){
                        onGuardar()
                    }
                }).catch(()=>{
                    setIsLoading(false);
                })
            }else{
                setIsLoading(false);
                toast.error('Las coordenadas no son validas.', {
                    position: "top-right",
                    autoClose: 4500,
                    pauseOnHover: false,
                });
            }
        }else{
            setIsLoading(false);
            toast.error('Las coordenadas no son validas.', {
                position: "top-right",
                autoClose: 4500,
                pauseOnHover: false,
            });
        }
    }
    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
          color: 'gray',
          fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
          marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border:1,
            height: 45,
            color: '#000'
          },
          '&:hover':{
            border: 1,
            borderRadius: 2,
            borderColor: 'gray',
            height: 40
          },
          '&.Mui-focused': {
            '&:hover':{
              border:0,
              height: 40
            }
          },
          color: 'gray',
          height: 40
        }
    }), [])

    useEffect(() => {
        setNombre(item?.nombre_t)
        setLocalidad(item?.lugarId_t)
        setCodPostal(item?.codpostal_t)
        setBarrio(item?.barrio_t)
        setCalle(item?.calle_t)
        setLatitud(item?.latitud_t)
        setLongitud(item?.longitud_t)
    }, [])

    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        ¡EDITAR PRE-SERVICIO!
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={8}>
                            <Typography sx={{color: '#000'}}>Nombre</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={nombre}
                                onChange={(e)=>setNombre(e?.target?.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography sx={{color: '#000'}}>Localidad Calendario</Typography>
                            <SelectLugaresCalendar
                                value={localidad}
                                onChange={(e)=>{
                                    setCodPostal(e?.codLocalidad);
                                    setLocalidad(e?.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Barrio</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={barrio}
                                onChange={(e)=>setBarrio(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Direccion</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={calle}
                                onChange={(e)=>setCalle(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Latitud</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={latitud}
                                onChange={(e)=>setLatitud(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography sx={{color: '#000'}}>Longitud</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                sx={styleInputHeight}
                                value={longitud}
                                onChange={(e)=>setLongitud(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                        isDisabled={
                            !nombre
                            || !localidad
                            || !codPostal
                            || !barrio
                            || !calle
                            || !latitud
                            || !longitud
                        }
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalEditarPreServicio