import { useState,useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleDefault from '../default/DialogTitleDefault';
import { Box, Card, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ButtonBasic, SelectReporteCategoria } from '../../components';
import ReportesService from '../../services/reportes.service';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './nuevoRep.css';
import AddIcon from '@mui/icons-material/Add';
import ModalReporteValidacion from './ModalReporteValidacion';
import { showModal } from 'react-redux-modal-provider';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmationDialog from '../default/ConfirmationDialog';

const ModalNuevoReporte = ({show, hideModal, item, onGuardar, permisos}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [validaciones, setValidaciones] = useState([]);
    const handleClose = () => hideModal();
    const [tituloRep, setTituloRep] = useState('')
    const [descripcionRep, setDescripcionRep] = useState('')
    const [categoria, setCategoria] = useState(null)
    const [camposAllTicket, setCamposAllTicket] = useState([
        {
            titulo: 'Nro Cuenta',
            campo: 'cuenta_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Nombre Cliente',
            campo: 'nombre_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'DNI/CUIT Cliente',
            campo: 'dni_cuit_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Domicilio Cliente',
            campo: 'domicilio_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Email Cliente',
            campo: 'email_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Telefono Cliente',
            campo: 'telefono_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Telefono2 Cliente',
            campo: 'telefono2_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Canal Cliente',
            campo: 'canal_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Segmento Cliente',
            campo: 'segmento_cliente',
            tipo: 'texto'
        },
        {
            titulo: 'Nro Servicio',
            campo: 'nro_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Nombre Servicio',
            campo: 'nombre_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Bonificaciones Servicio',
            campo: 'bonificaciones_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Segmento Servicio',
            campo: 'segmento_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Domicilio Servicio',
            campo: 'domicilio_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Nivel Vip Servicio',
            campo: 'nivel_vip_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Coordenadas Servicio',
            campo: 'coordenadas_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Codigo Postal Servicio',
            campo: 'ccpp_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Fecha de Instalación',
            campo: 'fec_inst_servicio',
            tipo: 'fecha'
        },
        {
            titulo: 'Fecha de Alta',
            campo: 'fec_alta_servicio',
            tipo: 'fecha'
        },
        {
            titulo: 'Bajada Servicio',
            campo: 'bajada_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Subida Servicio',
            campo: 'subida_servicio',
            tipo: 'texto'
        },
        {
            titulo: 'Nro Ticket',
            campo: 'id_tk',
            tipo: 'texto'
        },
        {
            titulo: 'Titulo',
            campo: 'titulo_tk',
            tipo: 'texto'
        },
        {
            titulo: 'Plantilla',
            campo: 'motivo_tk',
            tipo: 'select'
        },
        {
            titulo: 'Etapa del Ticket',
            campo: 'stageTicket_tk',
            tipo: 'select'
        },
        /* {
            titulo: 'Cuerpo',
            campo: 'cuerpo_tk',
            tipo: 'texto'
        }, */
        {
            titulo: 'Fecha de Vencimiento',
            campo: 'fechavencimiento_tk',
            tipo: 'fecha'
        },
        {
            titulo: 'Estado del Ticket',
            campo: 'estado_tk',
            tipo: 'select'
        },
        {
            titulo: 'Estado Vencimiento del Ticket',
            campo: 'estadoVencimiento_tk',
            tipo: 'select'
        },
        {
            titulo: 'Prioridad',
            campo: 'prioridad_tk',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Cierre',
            campo: 'cerrado_tk',
            tipo: 'fechahora'
        },
        {
            titulo: 'Continuidad del Ticket',
            campo: 'ticketIdOld_tk',
            tipo: 'texto'
        },
        {
            titulo: 'Canal del Ticket',
            campo: 'codCanal_tk',
            tipo: 'select'
        },
        {
            titulo: 'Tipo Ticket',
            campo: 'tipoTicketId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Sub-Tipo Ticket',
            campo: 'subTipoTicketId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Nivel Administrativo',
            campo: 'nivelAdmTkId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Motivo de Baja',
            campo: 'motivoBajaTk_tk',
            tipo: 'select'
        },
        {
            titulo: 'Tipo Servicio',
            campo: 'tecServicio_tk',
            tipo: 'select'
        },
        {
            titulo: 'Nodo',
            campo: 'nodoName_tk',
            tipo: 'texto'
        },
        {
            titulo: 'Panel',
            campo: 'panelName_tk',
            tipo: 'texto'
        },
        {
            titulo: 'Puerto Nap',
            campo: 'puertoNap_tk',
            tipo: 'select'
        },
        {
            titulo: 'Tecnologia Equipo RF',
            campo: 'tipoEquipoRF_tk',
            tipo: 'select'
        },
        {
            titulo: 'Equipo Registrado',
            campo: 'equipoTec_tk',
            tipo: 'select'
        },
        {
            titulo: 'Mac Equipo',
            campo: 'macEquipo_tk',
            tipo: 'texto'
        },
        {
            titulo: 'Fecha de Reapertura',
            campo: 'reopeningDate_tk',
            tipo: 'fechahora'
        },
        {
            titulo: 'Tipo de Cierre',
            campo: 'cierreTicketId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Sub-Tipo de Cierre',
            campo: 'subCierreTicketId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Segmento Detalle Adicional',
            campo: 'articuloFallaId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Problematica',
            campo: 'problematicaTkId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Solucion Tecnica',
            campo: 'solucionTecTkId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Ultima Asignacion',
            campo: 'asignado_actualmente',
            tipo: 'select'
        },
        {
            titulo: 'Fecha Ultima Asignacion',
            campo: 'fecha_asignado_actualmente',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Creacion',
            campo: 'createdAt',
            tipo: 'fechahora'
        },
        {
            titulo: 'Creado Por',
            campo: 'createdPersonaId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Cerrado Por',
            campo: 'closedPersonaId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Ultima Fecha de modificación',
            campo: 'updatedAt',
            tipo: 'fechahora'
        },
        {
            titulo: 'Ultima Modificacion Por',
            campo: 'updatedPersonaId_tk',
            tipo: 'select'
        },
        {
            titulo: 'Cant. de Comentarios',
            campo: 'total_comentarios',
            tipo: 'texto'
        },
        {
            titulo: 'Fecha Ultimo Comentario',
            campo: 'fecha_ultimo_comentario',
            tipo: 'texto'
        },
        {
            titulo: 'Usuario Ultimo Comentario',
            campo: 'usuario_ultimo_comentario',
            tipo: 'texto'
        },
        {
            titulo: 'Cant. de Asignaciones',
            campo: 'total_asignaciones',
            tipo: 'texto'
        },
        {
            titulo: 'Derivo en Asistencia',
            campo: 'derivoAsis_tk',
            tipo: 'select'
        },
        {
            titulo: 'Linea de Vista',
            campo: 'lineaVista_tk',
            tipo: 'select'
        },
        {
            titulo: 'Tiempo Transcurrido',
            campo: 'tiempo_transcurrido',
            tipo: 'fechahora'
        },
        {
            titulo: 'Tiempo Transcurrido - Formato',
            campo: 'tiempo_transcurrido_formato',
            tipo: 'fechahora'
        }
    ])
    const [camposAllPotCliente, setCamposAllPotCliente] = useState([
        {
            titulo: 'Nombre Completo',
            campo: 'nombreCompleto_c',
            tipo: 'texto'
        },
        {
            titulo: 'Tipo Documento',
            campo: 'tipoDoc_c',
            tipo: 'select'
        },
        {
            titulo: 'Documento',
            campo: 'documento_c',
            tipo: 'texto'
        },
        {
            titulo: 'Barrio',
            campo: 'barrio_c',
            tipo: 'texto'
        },
        {
            titulo: 'Direccion',
            campo: 'direccion_c',
            tipo: 'texto'
        },
        {
            titulo: 'Correo',
            campo: 'email_c',
            tipo: 'texto'
        },
        {
            titulo: 'Celular',
            campo: 'celular_c',
            tipo: 'texto'
        },
        {
            titulo: 'Latitud',
            campo: 'latitud_c',
            tipo: 'texto'
        },
        {
            titulo: 'Longitud',
            campo: 'longitud_c',
            tipo: 'texto'
        },
        {
            titulo: 'Codigo Postal',
            campo: 'cpostal_c',
            tipo: 'texto'
        },
        {
            titulo: 'Canal de Comunicacion',
            campo: 'canalcomu_c',
            tipo: 'select'
        },
        {
            titulo: 'IVA',
            campo: 'iva_c',
            tipo: 'select'
        },
        {
            titulo: 'Estado Cliente',
            campo: 'estadoCliente_c',
            tipo: 'select'
        },
        {
            titulo: 'Localidad Calendar',
            campo: 'lugarId_c',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Creacion',
            campo: 'createdAt',
            tipo: 'fechahora'
        },
        {
            titulo: 'Creado Por',
            campo: 'createdPersonaId_c',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Nacimiento',
            campo: 'fnacimiento_icon',
            tipo: 'fecha'
        },
    ])
    const [camposAllConsultas, setCamposAllConsultas] = useState([
        {
            titulo: 'Nro Cuenta',
            campo: 'contactoCuenta_con',
            tipo: 'texto'
        },
        {
            titulo: 'Nombre Completo',
            campo: 'nombreContacto_con',
            tipo: 'texto'
        },
        {
            titulo: 'Plan de Consulta',
            campo: 'planConsulta_con',
            tipo: 'select'
        },
        {
            titulo: 'Canal de comunicacion',
            campo: 'canal_con',
            tipo: 'select'
        },
        {
            titulo: 'Segmento',
            campo: 'segmento_con',
            tipo: 'select'
        },
        {
            titulo: 'Localidad',
            campo: 'localidadId_con',
            tipo: 'select'
        },
        {
            titulo: 'Direccion',
            campo: 'direccionConsulta_con',
            tipo: 'texto'
        },
        {
            titulo: 'Coordenadas',
            campo: 'coordenadasConsulta_con',
            tipo: 'texto'
        },
        {
            titulo: 'Celular',
            campo: 'celularConsulta_con',
            tipo: 'texto'
        },
        {
            titulo: 'Telefono',
            campo: 'telefonoConsulta_con',
            tipo: 'texto'
        },
        {
            titulo: 'Nro Pre-Servicio',
            campo: 'preServicioId_con',
            tipo: 'texto'
        },
        {
            titulo: 'Nro Servicio',
            campo: 'cuentaServicioId_con',
            tipo: 'texto'
        },
        {
            titulo: 'Observacion',
            campo: 'observaciones_con',
            tipo: 'texto'
        },
        {
            titulo: 'Estado',
            campo: 'estadoConsulta_con',
            tipo: 'select'
        },
        {
            titulo: 'Sub-Estado',
            campo: 'subEstadoConsulta_con',
            tipo: 'select'
        },
        {
            titulo: 'Bajada Máxima MB',
            campo: 'bajadaMaxMb_con',
            tipo: 'texto'
        },
        {
            titulo: 'Creado Por',
            campo: 'createdPersonaId_con',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Creacion',
            campo: 'createdAt',
            tipo: 'fechahora'
        },
    ])
    const [camposAllTags, setCamposAllTags] = useState([
        {
            titulo: 'Nombre Tag',
            campo: 'tag',
            tipo: 'select'
        },
        {
            titulo: 'Nro Ticket',
            campo: 'nroTkTag',
            tipo: 'texto'
        },
        {
            titulo: 'Texto Tag',
            campo: 'cuerpoTag',
            tipo: 'texto'
        },
        {
            titulo: 'Ubicación',
            campo: 'ubicacionTag',
            tipo: 'select'
        },
        {
            titulo: 'Creado Por',
            campo: 'createdPersonaIdTag',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Creacion',
            campo: 'createdAt',
            tipo: 'fechahora'
        },
    ])
    const [camposAllPreServicios, setCamposAllPreServicios] = useState([
        {
            titulo: 'Nro Pre-Servicio',
            campo: 'id_t',
            tipo: 'texto'
        },
        {
            titulo: 'Nro Cuenta',
            campo: 'cuentaId_t',
            tipo: 'texto'
        },
        {
            titulo: 'Nombre',
            campo: 'nombre_t',
            tipo: 'texto'
        },
        {
            titulo: 'Nombre de Items',
            campo: 'nombre_items',
            tipo: 'texto'
        },
        {
            titulo: 'Categoria',
            campo: 'categoria_t',
            tipo: 'select'
        },
        {
            titulo: 'Dirección',
            campo: 'direccion_t',
            tipo: 'texto'
        },
        {
            titulo: 'Nivel Vip',
            campo: 'nivelVip_t',
            tipo: 'select'
        },
        {
            titulo: 'Coordenadas',
            campo: 'coordenadas_t',
            tipo: 'texto'
        },
        {
            titulo: 'Celular',
            campo: 'celular_t',
            tipo: 'texto'
        },
        {
            titulo: 'Correo',
            campo: 'contactoEmail_t',
            tipo: 'texto'
        },
        {
            titulo: 'Codigo Postal',
            campo: 'codpostal_t',
            tipo: 'select'
        },
        {
            titulo: 'Canal de Comunicacion',
            campo: 'contratistaSoft_t',
            tipo: 'select'
        },
        {
            titulo: 'Cargo Instalacion',
            campo: 'cargoinstalacion_t',
            tipo: 'select'
        },
        {
            titulo: 'Nivel Autorizacion',
            campo: 'nivelAutorizacion_t',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Instalacion',
            campo: 'fechaInstalacionReal_t',
            tipo: 'fecha'
        },
        {
            titulo: 'Estado',
            campo: 'estadoServicio_t',
            tipo: 'select'
        },
        {
            titulo: 'Sub-Estado',
            campo: 'subEstadoServicio_t',
            tipo: 'select'
        },
        {
            titulo: 'Servicio de Cambio de Titular',
            campo: 'cambDomServicioId_t',
            tipo: 'texto'
        },
        {
            titulo: 'Fecha de Autorizacion',
            campo: 'fechaAutorizado_t',
            tipo: 'fechahora'
        },
        {
            titulo: 'Autorizado Por',
            campo: 'autorizadoxPersonalId_t',
            tipo: 'select'
        },
        {
            titulo: 'Creado Por',
            campo: 'createdPersonaId_t',
            tipo: 'select'
        },
        {
            titulo: 'Modificado Por',
            campo: 'updatedPersonaId_t',
            tipo: 'select'
        },
        {
            titulo: 'Fecha de Creacion',
            campo: 'createdAt',
            tipo: 'fechahora'
        },
    ])
    const dataInitialBasePotCliente = [
        {
            id: uuidv4(),
            title: 'Campos Habilitados',
            tasks: camposAllPotCliente
        },
        {
            id: uuidv4(),
            title: 'Seleccionados',
            tasks: item?.campos_rts || []
        },
    ]
    const dataInitialBaseTicket = [
        {
            id: uuidv4(),
            title: 'Campos Habilitados',
            tasks: camposAllTicket
        },
        {
            id: uuidv4(),
            title: 'Seleccionados',
            tasks: item?.campos_rts || []
        },
    ]
    const dataInitialBaseContactos = [
        {
            id: uuidv4(),
            title: 'Campos Habilitados',
            tasks: []
        },
        {
            id: uuidv4(),
            title: 'Seleccionados',
            tasks: []
        },
    ]
    const dataInitialBaseConsultas = [
        {
            id: uuidv4(),
            title: 'Campos Habilitados',
            tasks: camposAllConsultas
        },
        {
            id: uuidv4(),
            title: 'Seleccionados',
            tasks: item?.campos_rts || []
        },
    ]
    const dataInitialBaseTags = [
        {
            id: uuidv4(),
            title: 'Campos Habilitados',
            tasks: camposAllTags
        },
        {
            id: uuidv4(),
            title: 'Seleccionados',
            tasks: item?.campos_rts || []
        },
    ]
    const dataInitialBasePreServicio = [
        {
            id: uuidv4(),
            title: 'Campos Habilitados',
            tasks: camposAllPreServicios
        },
        {
            id: uuidv4(),
            title: 'Seleccionados',
            tasks: item?.campos_rts || []
        },
    ]
    const [camposSeleccionados, setCamposSeleccionados] = useState([])
    const reorder = (list, startIndex, endIndex) => {
        const result = [...list.tasks];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEnd = result => {
        if (!result.destination) return
        const { source, destination } = result

        if (source.droppableId !== destination.droppableId) {
            const sourceColIndex = camposSeleccionados.findIndex(e => e.id === source.droppableId)
            const destinationColIndex = camposSeleccionados.findIndex(e => e.id === destination.droppableId)
            /* sourceCol === lista */
            const sourceCol = camposSeleccionados[sourceColIndex]
            const destinationCol = camposSeleccionados[destinationColIndex]

            const sourceTask = [...sourceCol.tasks]
            const destinationTask = [...destinationCol.tasks]

            const [removed] = sourceTask.splice(source.index, 1)
            destinationTask.splice(destination.index, 0, removed)

            camposSeleccionados[sourceColIndex].tasks = sourceTask
            camposSeleccionados[destinationColIndex].tasks = destinationTask
            setCamposSeleccionados(camposSeleccionados)
        }else{
            const sourceColIndex = camposSeleccionados.findIndex(e => e.id === source.droppableId)
            const sourceCol = camposSeleccionados[sourceColIndex]
            const ordenado = reorder(sourceCol,source.index,destination.index)
            camposSeleccionados[sourceColIndex].tasks = ordenado
            setCamposSeleccionados(camposSeleccionados)
        }
    }
    const onSubmit = () => {
        setIsLoading(true);
        const camposSelect = camposSeleccionados[1].tasks
        const form ={
            titulo_rts: tituloRep,
            descripcion_rts: descripcionRep,
            campos_rts: camposSelect,
            tipo_rts: categoria,
            validaciones_rts: validaciones
        }
        if(item && item?.id_rts > 0){
            form.id_rts = item?.id_rts;
            ReportesService.update(form).then((resp)=>{
                if(onGuardar){
                    onGuardar()
                }
                handleClose()
            }).catch((e)=>{
                setIsLoading(false);
            })
        }else{
            ReportesService.register(form).then((resp)=>{
                if(onGuardar){
                    onGuardar()
                }
                handleClose()
            }).catch((e)=>{
                setIsLoading(false);
            })
        }
    };
    const onOpenModalValid = () =>{
        showModal(ModalReporteValidacion,{validaciones: validaciones, setValidaciones: setValidaciones,categoria: categoria })
    }
    const onEliminarValid = (idValid) =>{
        const validFilter = validaciones?.filter((ivd)=> ivd?.id !== idValid)
        setValidaciones(validFilter)
    }
    const onOpenModalDeleteValid = (idValid) =>{
        showModal(ConfirmationDialog, {
            title: 'Eliminar la validacion',
            message: '¿Esta seguro que desea eliminar la validacion?',
            onConfirm: () => onEliminarValid(idValid)
        });
    }
    useEffect(() => {
        if(permisos['reporte.ticket.historialTiempo']){
            const nuevoElemento = [{
                titulo: 'Tiempo Grupo Asignado',
                campo: 'tiempo_grupo_asignacion',
                tipo: 'fechahora'
            },{
                titulo: 'Nombre Grupo Asignado',
                campo: 'nombre_grupo_asignacion',
                tipo: 'fechahora'
            }]
            setCamposAllTicket((prevArray) => [...prevArray, ...nuevoElemento])
        }
    }, [])

    useEffect(() => {
        switch (categoria) {
            case 'potencialcliente':
                setValidaciones([])
                setCamposSeleccionados(dataInitialBasePotCliente)
                break;
            case 'ticket':
                setValidaciones([])
                setCamposSeleccionados(dataInitialBaseTicket)
                break;
            case 'contacto':
                setValidaciones([])
                setCamposSeleccionados(dataInitialBaseContactos)
                break;
            case 'consultas':
                setValidaciones([])
                setCamposSeleccionados(dataInitialBaseConsultas)
                break;
            case 'pre-servicio':
                setValidaciones([])
                setCamposSeleccionados(dataInitialBasePreServicio)
                break;
            case 'tags':
                setValidaciones([])
                setCamposSeleccionados(dataInitialBaseTags)
                break;
            default:
                setValidaciones([])
                setCamposSeleccionados([])
                break;
        }
    }, [categoria,item,camposAllTicket])

    useEffect(() => {
        if(item){
            setTituloRep(item?.titulo_rts)
            setCategoria(item?.tipo_rts)
            setDescripcionRep(item?.descripcion_rts)
            if(item?.campos_rts && item?.campos_rts?.length > 0){
                const itemRemove = [];
                item?.campos_rts?.forEach((it)=>{
                    if(!itemRemove.includes(it?.campo)){
                        itemRemove.push(it?.campo)
                    }
                })
                if(item?.tipo_rts === 'ticket'){
                    const filterData = camposAllTicket?.filter((cAtK)=> !itemRemove.includes(cAtK?.campo))
                    if(permisos['reporte.ticket.historialTiempo']){
                        const existeTiempo = itemRemove?.find((et)=> et === 'tiempo_grupo_asignacion' )
                        if(!existeTiempo){
                            filterData.push({
                                titulo: 'Tiempo Grupo Asignado',
                                campo: 'tiempo_grupo_asignacion',
                                tipo: 'fechahora'
                            })
                        }
                        const existeNombre = itemRemove?.find((et)=> et === 'nombre_grupo_asignacion' )
                        if(!existeNombre){
                            filterData.push({
                                titulo: 'Nombre Grupo Asignado',
                                campo: 'nombre_grupo_asignacion',
                                tipo: 'fechahora'
                            })
                        }
                    }
                    setCamposAllTicket(filterData)
                }else if(item?.tipo_rts === 'potencialcliente'){
                    const filterData = camposAllPotCliente?.filter((cAtK)=> !itemRemove.includes(cAtK?.campo))
                    setCamposAllPotCliente(filterData)
                }else if(item?.tipo_rts === 'consultas'){
                    const filterData = camposAllConsultas?.filter((cAtK)=> !itemRemove.includes(cAtK?.campo))
                    setCamposAllConsultas(filterData)
                }else if(item?.tipo_rts === 'tags'){
                    const filterData = camposAllTags?.filter((cAtK)=> !itemRemove.includes(cAtK?.campo))
                    setCamposAllTags(filterData)
                }else if(item?.tipo_rts === 'pre-servicio'){
                    const filterData = camposAllPreServicios?.filter((cAtK)=> !itemRemove.includes(cAtK?.campo))
                    setCamposAllPreServicios(filterData)
                }
            }
            setTimeout(() => {
                if(item?.validaciones_rts && item?.validaciones_rts?.length > 0){
                    setValidaciones(item?.validaciones_rts)
                }
            }, 100);
        }
    }, [item])

    const styleInputHeight = useMemo(() => ({
        '& .MuiInputLabel-root':{
            color: 'gray',
            fontSize: 15
        },
        '& fieldset': {
            borderRadius: 2,
            border: 1,
            borderColor: 'gray',
            height: 45
        },
        '& label':{
            marginTop: -0.5,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border:1,
                height: 45,
                color: '#000'
            },
            '&:hover':{
                border: 1,
                borderRadius: 2,
                borderColor: 'gray',
                height: 40
            },
            '&.Mui-focused': {
                '&:hover':{
                border:0,
                height: 40
                }
            },
            color: 'gray',
            height: 40
        }
    }), [])
    return(
        <Dialog open={show} disableEscapeKeyDown maxWidth='md' fullWidth sx={{backdropFilter: 'blur(0.4px);'}}>
            <Box sx={{background: '#fff'}}>
                <DialogTitleDefault id="add-canal-priv" onClose={handleClose}>
                    <Typography sx={{flex:1,textAlign: 'center', color: '#000',fontWeight: 'bold'}} variant='h5'>
                        Nuevo Reporte
                    </Typography>
                </DialogTitleDefault>
                <Divider sx={{background: 'gray'}} />
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Nombre</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={tituloRep}
                                onChange={(e)=>setTituloRep(e.target.value)}
                                sx={styleInputHeight}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: '#000'}}>Categoria</Typography>
                            <SelectReporteCategoria
                                value={categoria}
                                onChange={(e)=>setCategoria(e?.value)}
                                disabled={item?.id_rts}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000'}}>Descripcion (opcional)</Typography>
                            <TextField
                                fullWidth
                                autoComplete='off'
                                value={descripcionRep}
                                onChange={(e)=>setDescripcionRep(e.target.value)}
                                sx={styleInputHeight}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color: '#000', fontWeight: 'bold'}}>Seleccionar Campos</Typography>
                            <Typography sx={{color: '#000'}}>Arrastra los "Campos" a utilizar hacia la columna de "Seleccionados"</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <div className='kanban'>
                                    {camposSeleccionados?.map((section)=>(
                                        <Droppable
                                            key={section.id}
                                            droppableId={section.id}
                                        >
                                            {(provided)=>(
                                                <>
                                                    <Box
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className='kanban__section side'
                                                        sx={{border: 1,borderColor: '#000',minHeight: 550,overflow: 'auto', maxHeight: 500}}
                                                    >
                                                        <Typography sx={{color: '#000',fontWeight: 'bold',textAlign: 'center'}}>
                                                            {section.title}
                                                        </Typography>
                                                        <Divider sx={{background: 'gray', mb:1}} />
                                                        <div className='kanban__section__content'>
                                                            { section.tasks?.map((tsk,index)=>(
                                                                <Draggable
                                                                    key={tsk.campo}
                                                                    draggableId={tsk.campo}
                                                                    index={index}
                                                                >
                                                                    {({draggableProps,innerRef,dragHandleProps},snapshot)=>(
                                                                        <div
                                                                            ref={innerRef}
                                                                            {...draggableProps}
                                                                            {...dragHandleProps}
                                                                            style={{
                                                                                ...draggableProps.style,
                                                                                opacity: snapshot.isDragging ? '0.5' : '1'
                                                                            }}
                                                                        >
                                                                            <Card sx={{background: '#fff !important',border:1, borderColor: '#000'}}>
                                                                                <Typography sx={{color: '#000', textAlign: 'center'}}>
                                                                                    {tsk.titulo}
                                                                                </Typography>
                                                                            </Card>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    </Box>
                                                </>
                                            )}
                                        </Droppable>
                                    ))}
                                </div>
                            </DragDropContext>
                        </Grid>
                        <Grid item xs={6} display='flex' alignItems='center'>
                            <Typography sx={{color: '#000', fontWeight: 'bold'}}>Validaciones</Typography>
                        </Grid>
                        <Grid item xs={6} display='flex' justifyContent='end'>
                            <ButtonBasic
                                iconStart={<AddIcon sx={{color: '#0364ff'}} />}
                                textButton='Nueva Validacion'
                                variant='wk'
                                color='nextWhite'
                                sx={{border: 1 , borderColor: '#0364ff'}}
                                onClick={()=>onOpenModalValid()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {validaciones?.length > 0 ? (
                                validaciones.map((valid)=>(
                                    <Card sx={{background: '#fff !important',marginTop:2,p:1,boxShadow: 3}}>
                                        <Grid container>
                                            <Grid item xs={10}>
                                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                                    <Typography sx={{color: '#000', fontWeight: 'bold'}} component='div'>Campo: <Box sx={{fontWeight:'normal', display:'inline',ml:0.2}}>{valid?.inputTitle}</Box></Typography>
                                                    <Typography sx={{color: '#000', fontWeight: 'bold',ml:2}} component='div'>Tipo de consulta: <Box sx={{fontWeight:'normal', display:'inline',ml:0.2}}>{valid?.consulta}</Box></Typography>
                                                </Box>
                                                <Typography sx={{color: '#000'}} component='div'>{}</Typography>
                                                <Box sx={{display: 'flex', flexDirection: 'row', marginTop:0}}>
                                                    <Typography sx={{color: '#000', fontWeight: 'bold'}} component='div'>Observacion: <Box sx={{fontWeight:'normal', display:'inline',ml:0.2}}>{valid?.itemArrString?.toString() || '-'}</Box></Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Box>
                                                    <IconButton sx={{boxShadow: 3}} onClick={()=>onOpenModalDeleteValid(valid?.id)}>
                                                        <DeleteOutlineOutlinedIcon sx={{color: '#15294b'}} />
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ))
                            ):(
                                <Box sx={{background: '#f6f8fc',minHeight: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography sx={{color: '#808b9e', fontWeight: 'bold', textAlign: 'center'}}>Aun no se realizaron validaciones</Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider sx={{background: 'gray'}} />
                <DialogActions sx={{display:'flex', justifyContent:'center'}}>
                    <ButtonBasic
                        textButton='Cancelar'
                        variant='wk'
                        color='cancel'
                        onClick={()=>handleClose()}
                        isLoading={isLoading}
                    />
                    <ButtonBasic
                        textButton='Guardar'
                        onClick={()=>onSubmit()}
                        isLoading={isLoading}
                        variant='wk'
                        color='nextStep'
                    />
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ModalNuevoReporte