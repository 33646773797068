import axios from 'axios';
const base = 'tratos';
function getAll(query = null) {
    return axios.get(base, {
        params: query
    });
}

function register(data) {
    return axios.post(base, data);
}

function getById(id,query = null) {
    return axios.get([base, id].join('/'),{
        headers: query
    });
}

function update(data) {
    return axios.put([base, data.id_t].join('/'), data);
}

function destroy(id) {
    return axios.delete([base, id].join('/'));
}

function getByIdCuenta(id) {
    return axios.get([base, id].join('/cuenta/'));
}

function confirmarInstalacionNubi(data) {
    const form = new FormData();
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    if(data?.sendEmail){
        form.append('sendEmail',data.sendEmail);
    }
    if(data?.commentTicket){
        form.append('commentTicket',data.commentTicket);
    }
    if(data?.asigTk){
        form.append('asigTk',data.asigTk);
    }
    if(data?.ticketId){
        form.append('ticketId',data.ticketId);
    }
    form.append('fecha',data.fecha);
    form.append('id_t',data.id_t);
    if(data?.otImg){
        if(Array.isArray(data.otImg) && data.otImg.length>0){
            form.append('otImg',data.otImg[0]);
        } else {
            form.append('otImg',data.otImg);
        }
    }
    return axios.put([base, data.id_t].join('/instconf/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}
function autorizarBonificacion(id) {
    return axios.put([base, 'autorizar',id].join('/'));
}
function changeEstadoSoftland(data) {
    return axios.put([base, 'estado/soft'].join('/'), data);
}
function consultarCambioTitularServicio(query) {
    return axios.get([base, 'cambio/titular'].join('/'),{
        params: query
    });
}

function confirmarDocumentacionCambTitular(data) {
    const form = new FormData();
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    form.append('ticketId',data.ticketId);
    form.append('idPreServicio',data.idPreServicio);
    if(data?.constanciaImg){
        if(Array.isArray(data.constanciaImg) && data.constanciaImg.length>0){
            form.append('constanciaImg',data.constanciaImg[0]);
        } else {
            form.append('constanciaImg',data.constanciaImg);
        }
    }
    if(data?.contratoImg){
        if(Array.isArray(data.contratoImg) && data.contratoImg.length>0){
            form.append('contratoImg',data.contratoImg[0]);
        } else {
            form.append('contratoImg',data.contratoImg);
        }
    }
    return axios.put([base, data.idPreServicio].join('/doccambtitular/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function preView(data) {
    return axios.post([base, 'vista/contrato'].join('/'),data,{ responseType: 'blob' });
}

function changeEstadoPreServicio(data) {
    return axios.put([base, 'update-estado',data?.id_t].join('/'), data);
}

function registerSC(data) {
    return axios.post(`${base}/cuenta/sc`, data);
}

function noAutorizarBonificacion(data) {
    return axios.put([base, 'estado/noautorizado'].join('/'),data);
}

function obtenerCuadrillasDisponibles(data) {
    return axios.get('calv2/cuadrillas/tercerizada', data);
}

function confirmChangePrefa(data) {
    const form = new FormData();
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    form.append('estado',data.estado);
    form.append('ticketId',data.ticketId);
    form.append('tratoId',data.tratoId);
    form.append('comentario',data.comentario);
    form.append('tecnologiaPrefa_t',data?.tecnologiaPrefa_t);
    if(data?.mbPrefa_t){
        form.append('mbPrefa_t',data?.mbPrefa_t);
    }

    return axios.post([base,'prefa', 'change'].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function confirmClientePrefa(data) {
    const form = new FormData();
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    form.append('estado',data.estado);
    form.append('items',JSON.stringify(data.items));
    form.append('tratoId',data.tratoId);
    form.append('ticketId',data.ticketId);
    form.append('motivoId',data.motivoId);
    form.append('cuerpo_tk',data.cuerpo_tk);
    form.append('textTitulo',data.textTitulo);
    form.append('costoInst',data?.costoInst);
    form.append('routerInt',data?.routerInt);
    return axios.post([base,'prefa', 'confirm'].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function getByIdPrefa(id) {
    return axios.get([base,'prefa', id].join('/'));
}

function getByIdPrevConfirmInst(id) {
    return axios.get([base,'prevconfirm', id].join('/'));
}

function confirmNegativaInst(data) {
    const form = new FormData();
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    form.append('ticketId',data.ticketId);
    form.append('tratoId',data.tratoId);
    form.append('estadoSelect',data.estadoSelect);
    form.append('subEstado',data.subEstado);
    form.append('asunto',data.asunto);
    form.append('nombreCliente',data.nombreCliente);
    form.append('cuerpoCorreo',data.cuerpoCorreo);
    if(data?.ticketIdDA){
        form.append('ticketIdDA',data.ticketIdDA);
    }
    if(data?.cuerpoDA){
        form.append('cuerpoDA',data.cuerpoDA);
    }
    if(data?.destino){
        form.append('destino',data.destino);
    }
    if(data?.ccArr){
        form.append('ccArr', JSON.stringify(data.ccArr));
    }
    return axios.put([base,'instnegativa', data?.tratoId].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function confirmCanceladaInst(data) {
    const form = new FormData();
    if(data?.filesCompress?.length > 0){
        data.filesCompress?.forEach((imgx,index) => {
            form.append(`foto${index+1}`,imgx);
        });
    }
    form.append('ticketId',data.ticketId);
    form.append('tratoId',data.tratoId);
    form.append('subEstado',data.subEstado);
    if(data?.ticketIdDA){
        form.append('ticketIdDA',data.ticketIdDA);
    }
    if(data?.cuerpoDA){
        form.append('cuerpoDA',data.cuerpoDA);
    }
    return axios.put([base,'instcancelada', data?.tratoId].join('/'), form,{
        headers: {
            'Content-Type': `multipart/form-data; boundary=${form['_boundary']}`
        },
    });
}

function changeNivelVip(data) {
    return axios.put([base, 'nivelvip', data?.tratoId].join('/'),data);
}

function changePreServicio(data) {
    return axios.put([base, 'change-pre-servicio', data?.id_t].join('/'),data);
}

const TratoService = {
    getAll,
    register,
    getById,
    update,
    destroy,
    getByIdCuenta,
    confirmarInstalacionNubi,
    changeEstadoSoftland,
    consultarCambioTitularServicio,
    autorizarBonificacion,
    confirmarDocumentacionCambTitular,
    preView,
    changeEstadoPreServicio,
    registerSC,
    noAutorizarBonificacion,
    obtenerCuadrillasDisponibles,
    confirmChangePrefa,
    confirmClientePrefa,
    getByIdPrefa,
    getByIdPrevConfirmInst,
    confirmNegativaInst,
    confirmCanceladaInst,
    changeNivelVip,
    changePreServicio
};
export default TratoService;