import { useState, useEffect } from 'react';
import { Box, Card, Divider, Grid, IconButton, Tooltip, tooltipClasses, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { showModal } from "react-redux-modal-provider";
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonBasic, SelectNivelVIP, TextInfoTicket } from '../../components';
import TratoService from '../../services/tratos.service';
import TabCuentaTicket from '../contacto/tabs/TabCuentaTicket';
import AuxiliarFunction from '../../function/AuxiliarFunction';
import DownloadFile from '../../services/downloadFile.service';
import FechaFunction from '../../function/FechaFunction';
import TabContactoPreServicio from '../contacto/tabs/TabContactoPreServicio';
import ConfirmationDialog from '../../modals/default/ConfirmationDialog';
import TabCuentaTareas from '../contacto/tabs/TabCuentaTareas';
import CalendarioV2Service from '../../services/calendarioV2.service';
import ApiSoftland from '../../services/apis.softland.service';
import TabCuentaHistorico from '../contacto/tabs/TabCuentaHistorico';
import LogService from '../../services/log.service';
import ModalEstadoPreServicio from '../../modals/servicio/ModalEstadoPreServicio';
import ReportesService from '../../services/reportes.service';
import TabCuentaRecodatorios from '../contacto/tabs/TabCuentaRecodatorios';
import RecordatorioService from '../../services/recordatorio.service';
import usePermisos from '../../hooks/usePermisos';
import ModalEditarPreServicio from '../../modals/tratos/ModalEditarPreServicio';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>{children}</Box>
        )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const PreServicioVerScreen = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const {id,type} = useParams();
    const permisos = usePermisos();
    const [isLoading, setIsLoading] = useState(true);
    const [trato, setTrato] = useState();
    const [tareas, setTareas] = useState([]);
    const [logs, setLogs] = useState([]);
    const [recordatorios, setRecordatorios] = useState([]);
    const excluirEstados = [
        'PENDIENTE DE ALTA EN SOFTLAND POR VALIDACION DE DOCUMENTACION',
        'INSTALADO',
        'NUEVO PRE-SERVICIO CORPORATIVO',
        'NUEVO PRE-SERVICIO GOBIERNO',
        'INSTALACION CANCELADA',
        'CANCELADO POR FALTA DE DOCUMENTACIÓN',
        'NO AUTORIZADO - VIP'
    ];
    const navigate = useNavigate();
    const actualizar = async() =>{
        await TratoService.getById(id,{type}).then((r)=>{
            if(r?.cuentaContratoId_t){
                navigate(`/servicio/${r.cuentaContratoId_t}/cuenta`)
            }
            setTrato(r)
        })
        .catch(()=>{
            navigate('/tratos');
        })
        .finally(()=>{
            setIsLoading(false)
        })
    }
    const actualizarTareas = async() =>{
        await CalendarioV2Service.tareasCalendar({contratoId: id}).then((resp)=>{
            setTareas(resp)
        })
    }
    const actualizarHistorico = async() =>{
        await LogService.getAllContacto({preServicio: id}).then((resp)=>{
            setLogs(resp)
        })
    }
    useEffect(() => {
        actualizar();
        actualizarTareas();
        actualizarHistorico();
        actualizarRecordatorios();
        document.title = `Pre-Servicio #${id}`;
    }, [id])
    const onGuardar = () => {
        actualizar();
        actualizarTareas();
        actualizarHistorico();
        actualizarRecordatorios();
    }
    /* const onOpenConfirmarInstalacion = async() =>{
        showModal(ModalConfirmInstalacion, {tratoId: id, onGuardar: actualizar});
    } */
    const downloadPdfContrato = async() =>{
        AuxiliarFunction.downloadFile(DownloadFile.contratoNubiPdf({id:id}),'contrato')
    }
    const checkVinculacion = async() => {
        await ApiSoftland.checkServicioSoftland(id);
    }
    const onClickNameCliente = () =>{
        if(trato?.cuentaId_t){
            navigate(`/contactos/${trato?.cuentaId_t}/cuenta`)
        }else{
            navigate(`/contactos/${trato?.contactoId_t}/nubicom`)
        }
    }
    const autorizarPreServicio = async() => {
        setIsLoading(true)
        await TratoService.autorizarBonificacion(id).then(()=>{
            actualizar();
            setTimeout(() => {
                actualizarTareas();
                actualizarHistorico();
            }, 800);
        }).finally(()=>{
            setIsLoading(false)
        })
    }

    const noAutorizarPreServicio = async() => {
        setIsLoading(true)
        await TratoService.noAutorizarBonificacion({idTrato:id}).then(()=>{
            actualizar();
            setTimeout(() => {
                actualizarTareas();
                actualizarHistorico();
            }, 800);
        }).finally(()=>{
            setIsLoading(false)
        })
    }

    const onOpenModalAutorizar = () =>{
        showModal(ConfirmationDialog, {
            title: 'Autorizar Pre-Servicio',
            message: `¿Esta seguro que desea autorizar el pre-servicio #${id} ?`,
            onConfirm: () => autorizarPreServicio()
        });
    }

    const onOpenModalNoAutorizar = () =>{
        showModal(ConfirmationDialog, {
            title: 'Rechazar Pre-Servicio',
            message: `¿Esta seguro que desea no autorizar el pre-servicio #${id} ?`,
            onConfirm: () => noAutorizarPreServicio()
        });
    }

    const changeEstadoPreServicio = () =>{
        showModal(ModalEstadoPreServicio, { item: trato, onGuardar: onGuardar });
    }
    const editPreServicioModal = () =>{
        showModal(ModalEditarPreServicio, { item: trato, onGuardar: onGuardar });
    }
    const onReporteServicioId = async() => {
        await AuxiliarFunction.downloadFile(
            ReportesService.reporteServicioId(id),
            id
        )
    }
    const actualizarRecordatorios = async() =>{
        await RecordatorioService.getAll({preServicioId: id}).then((resp)=>{
            setRecordatorios(resp)
        })
    }

    const onSelectVip = async(itemValue) => {
        await TratoService.changeNivelVip({tratoId: id, nivelVip_t: itemValue}).then(()=>{
            actualizar();
            actualizarHistorico();
        })
    }
    return (
        <Box sx={{width:'100%',height: '100%',overflow:'auto'}} className='side'>
        <Grid container p={3} mb={5} spacing={1}>
            <Grid item xs={12} md={3}>
            <Card sx={{background: '#fff !important',p:2,borderRadius:4,boxShadow: "0px -3px 0px 0px #15294b"}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <LightTooltip  title="Atrás" placement="top">
                        <IconButton sx={{boxShadow: 3}} onClick={()=>navigate(-1)}>
                            <ArrowBackIcon sx={{color: '#15294b'}} />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip  title="Editar" placement="top">
                        <IconButton
                            sx={{
                                boxShadow: 3,
                                backgroundColor: permisos['editar.pre_servicio.Nubi'] ? '#fff' : '#9d9ea4 !important'
                            }}
                            onClick={()=>editPreServicioModal()}
                            disabled={!permisos['editar.pre_servicio.Nubi']}
                        >
                            <EditIcon sx={{color: '#15294b', fontSize: 16}} />
                        </IconButton>
                    </LightTooltip>
                </Box>
                <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center',mb: 1, flexDirection: 'column'}}>
                    <Box sx={{background: '#f5f6f7', borderRadius:80, width:80, height: 80, display:'flex', justifyContent: 'center', alignItems: 'center',border:1 , borderColor: '#15294b'}}>
                        <ShoppingCartOutlinedIcon sx={{color: '#15294b',fontSize: 55}} />
                    </Box>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold',textAlign: 'center',mt: 1}} variant='h4'>{`Pre-Servicio #${trato?.id_t}`}</Typography>
                </Box>
                <Divider sx={{background: '#8993a4 !important',marginBottom:1,width: '100%'}} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',mb:2}}>
                    <Box sx={{background: '#e6f0ff', borderRadius:50, width:35, height: 35, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <AccountCircleIcon sx={{color: '#15294b',fontSize: 25}} />
                    </Box>
                    <Typography
                        sx={{color: '#15294b',fontWeight: 'bold',textAlign: 'center',pl:0.5,cursor: 'pointer', '&:hover':{color: '#0364ff'}}}
                        variant='h6'
                        onClick={()=>onClickNameCliente()}
                    >
                        {trato?.nombre_t}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column',mb: 2}}>
                    {trato?.listaPrecioSoft_t && trato?.listaPrecioSoft_t !== 'COR1      ' && (
                        <ButtonBasic
                            textButton='Descargar Contrato'
                            iconStart={<SaveAltOutlinedIcon />}
                            onClick={()=>downloadPdfContrato()}
                            variant='wk'
                            color='nextWhite'
                            sx={{border: 1}}
                        />
                    )}
                    <ButtonBasic
                        textButton='Revisar Vinculacion'
                        iconStart={<HowToRegIcon />}
                        onClick={()=>checkVinculacion()}
                        variant='wk'
                        color='nextWhite'
                        sx={{border: 1,mt:1}}
                    />
                </Box>
                {(trato?.nivelAutorizacion_t === 'bonificacion_nivel2' || trato?.nivelAutorizacion_t === 'bonificacion_nivel3') && !trato?.autorizadoxPersonalId_t &&(
                    <Box sx={{display: 'flex', justifyContent: 'center',mb: 2,flexDirection: 'column'}}>
                        <ButtonBasic
                            textButton='Autorizar Pre-Servicio'
                            iconStart={<AssignmentTurnedInOutlinedIcon />}
                            onClick={()=>onOpenModalAutorizar()}
                            variant='wk'
                            color='nextWhite'
                            sx={{border: 1}}
                        />
                        <ButtonBasic
                            textButton='No Autorizar Pre-Servicio'
                            iconStart={<AssignmentTurnedInOutlinedIcon />}
                            onClick={()=>onOpenModalNoAutorizar()}
                            variant='wk'
                            color='nextWhite'
                            sx={{border: 1,color: 'red', mt: 1}}
                        />
                    </Box>
                )}
                { !excluirEstados?.includes(trato?.estadoServicio_t) && (
                    <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column',mb: 1}}>
                        <ButtonBasic
                            textButton='Cambiar Estado'
                            iconStart={<ChangeCircleOutlinedIcon />}
                            onClick={()=>changeEstadoPreServicio()}
                            variant='wk'
                            color='nextWhite'
                            sx={{border: 1}}
                        />
                    </Box>
                )}
                <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column',mb: 2}}>
                    <ButtonBasic
                        textButton='Descargar Reporte'
                        iconStart={<BackupTableIcon />}
                        onClick={()=>onReporteServicioId()}
                        variant='wk'
                        color='nextWhite'
                        sx={{border: 1}}
                    />
                </Box>
                <Divider sx={{background: '#8993a4 !important',marginBottom:2,width: '100%'}} />
                <Typography sx={{color: '#15294b',fontWeight: 'bold',textAlign: 'left',mb: 2}} variant='h6'>Datos del Pre-Servicio</Typography>
                <TextInfoTicket titulo='Estado' descripcion={trato?.estadoServicio_t} />
                {trato?.nivelVip_t && !permisos['bonificacion_nivel3'] && !permisos['bonificacion_nivel2'] && (
                    <TextInfoTicket titulo='Nivel VIP' descripcion={trato?.nivelVip_t} />
                )}
                {(permisos['bonificacion_nivel3'] || permisos['bonificacion_nivel2']) && (
                    <Box sx={{width: '90%',ml: 1, mb: 1,mt: 1}}>
                        <Typography sx={{color: '#15294b',fontWeight: 'bold', fontSize: 13, ml: 1}}>
                            Nivel VIP
                        </Typography>
                        <SelectNivelVIP
                            isClearable
                            value={trato?.nivelVip_t}
                            onChange={(e)=>onSelectVip(e?.value)}
                        />
                    </Box>
                )}
                {trato?.subEstadoServicio_t && (
                    <TextInfoTicket titulo='Sub-Estado' descripcion={trato?.subEstadoServicio_t} />
                )}
                <TextInfoTicket titulo='Total Ticket' descripcion={trato?.tickets?.length} />
                {trato?.fechaInstalacionReal_t &&(
                    <TextInfoTicket titulo='Fecha Instalacion' descripcion={FechaFunction.format( trato?.fechaInstalacionReal_t, 'dd/MM/yyyy')} />
                )}
                <TextInfoTicket titulo='Dirección' descripcion={trato?.direccion_t} />
                {trato?.prefactibilidadRF_t && (
                    <TextInfoTicket titulo='Prefactibilidad RF' descripcion={trato?.prefactibilidadRF_t} />
                )}
                {trato?.prefactibilidadFO_t && (
                    <TextInfoTicket titulo='Prefactibilidad FO' descripcion={trato?.prefactibilidadFO_t} />
                )}
                <TextInfoTicket titulo='Creado por' descripcion={trato?.nombre_p} />
                {trato && trato?.createdAt &&(
                    <TextInfoTicket titulo='Creado el' descripcion={FechaFunction.format(new Date(trato?.createdAt), 'dd/MM/yyyy HH:mm:ss')} />
                )}
                {(trato?.nivelAutorizacion_t === 'bonificacion_nivel2' || trato?.nivelAutorizacion_t === 'bonificacion_nivel3') && !trato?.autorizadoxPersonalId_t && trato?.estadoServicio_t !== 'NO AUTORIZADO - VIP' &&(
                    <Typography sx={{color: '#e01010',fontWeight: 'bold',textAlign: 'center',mb: 2}} variant='h5'>Pendiente de aprobación</Typography>
                )}
                {trato?.autorizadoxPersonalId_t?.nombre_p && trato?.estadoServicio_t !== 'NO AUTORIZADO - VIP' &&(
                    <>
                        <Divider sx={{background: '#8993a4 !important',marginBottom:2,width: '100%'}} />
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',textAlign: 'left',mb: 1}} variant='h6'>Autorización</Typography>
                        <TextInfoTicket titulo='Autorizado Por:' descripcion={trato?.autorizadoxPersonalId_t?.nombre_p} />
                        <TextInfoTicket titulo='Fecha Autorizado:' descripcion={FechaFunction.format( new Date(trato?.fechaAutorizado_t), 'dd/MM/yyyy HH:mm:ss')} />
                    </>
                )}
                {trato?.autorizadoxPersonalId_t?.nombre_p && trato?.estadoServicio_t === 'NO AUTORIZADO - VIP' &&(
                    <>
                        <Divider sx={{background: '#8993a4 !important',marginBottom:2,width: '100%'}} />
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',textAlign: 'left',mb: 1}} variant='h6'>No Autorizado</Typography>
                        <TextInfoTicket titulo='No Autorizado Por:' descripcion={trato?.autorizadoxPersonalId_t?.nombre_p} />
                        <TextInfoTicket titulo='Fecha No Autorizado:' descripcion={FechaFunction.format( new Date(trato?.fechaAutorizado_t), 'dd/MM/yyyy HH:mm:ss')} />
                    </>
                )}
            </Card>
            </Grid>
            <Grid item xs={12} md={9}>
                <Card sx={{background: '#fff !important',p:2,borderRadius:4}}>
                    <Box sx={{ borderBottom: 1, borderColor: '#6b788e' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            sx={{
                                '& .MuiTabs-indicator':{
                                    background: '#0364ff'
                                },
                                '& .MuiButtonBase-root':{
                                color: '#6b788e',
                                fontWeight: 'bold'
                                },
                                '& .MuiButtonBase-root.Mui-selected':{
                                    color: '#0364ff'
                                }
                            }}
                        >
                            <Tab label="Tickets" {...a11yProps(0)} sx={{ minWidth: "150px" }}/>
                            <Tab label="Items" {...a11yProps(1)} sx={{ minWidth: "150px" }}/>
                            <Tab label="Tareas" {...a11yProps(2)} sx={{ minWidth: "150px" }}/>
                            <Tab label="Historico" {...a11yProps(3)} sx={{ minWidth: "150px" }}/>
                            <Tab label="Recordatorios" {...a11yProps(4)} sx={{ minWidth: "150px" }}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <TabCuentaTicket
                            tickets={trato?.tickets}
                            onGuardar={onGuardar}
                            servicioNewTk={{
                                nombreCompleto: trato?.nombre_t,
                                cuentaId: trato?.cuentaId_t,
                                contactoId: trato?.contactoId_t,
                                preServicioId: id
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TabContactoPreServicio preServicios={trato?.tratoserv} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TabCuentaTareas tareas={tareas} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <TabCuentaHistorico
                            historico={logs}
                            infoCliente={{
                                contactoId: trato?.contactoId_t,
                                cuentaId: trato?.cuentaId_t,
                                hiddenSelect: true,
                                preServicioId: id
                            }}
                            onGuardar={actualizarHistorico}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <TabCuentaRecodatorios
                            recordatoriosAll={recordatorios}
                            contactoId={trato?.contactoId_t}
                            cuenta={trato?.cuentaId_t}
                            nombreCompleto={trato?.nombre_t}
                            preServicioId={id}
                            onGuardar={actualizarRecordatorios}
                        />
                    </TabPanel>
                </Card>
            </Grid>
        </Grid>
        </Box>
    )
}

export default PreServicioVerScreen