import { useState, useEffect } from "react";
import { Avatar, Box, Card, Collapse, Divider, Grid, IconButton, TextField, Tooltip, tooltipClasses, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showModal } from "react-redux-modal-provider";
import Compressor from 'compressorjs';
import ReactPlayer from 'react-player'
import { PhotoConsumer } from 'react-photo-view';
import {toast} from 'react-toastify';
import { saveAs } from 'file-saver';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FechaFunction from "../../function/FechaFunction";
import ConsultasService from "../../services/consultas.service";
import InputFileNotaConsulta from "../inputs/InputFileNotaConsulta";
import CardUploadFile from "./CardUploadFile";
import MenuOptionsConsulta from "../menu/MenuOptionsConsulta";
import ModalCrearRecordatorio from "../../modals/recordatorios/ModalCrearRecordatorio";
import ModalRecordatoriosConsulta from "../../modals/recordatorios/ModalRecordatoriosConsulta";

const CardConsultaV2 = ({con, actualizar,actualizarRecordatorios}) => {
    const user = useSelector((state) => state.auth.currentUser);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [textNota, setTextNota] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesCompress, setFilesCompress] = useState([]);
    const [openComentario, setOpenComentarios] = useState(false);
    const handleClickComentarios = () => setOpenComentarios(!openComentario);
    const extensionPermitida = ['png','jpg','jpeg'];
    const arrPlanesFO = ['PLAN 200 MB','PLAN 400 MB','PLAN 700 MB','PLAN 1000 MB','PLAN BASIC 100 MB','PLAN PLUS 300 MB','PLAN LIFE 600 MB','PLAN FO 600/300','FIBRA OPTICA']
    const acceptInputFile = ''
    const urlNubi = 'https://zeta.strongsystems.com.ar:8000/api/img?url=consultas/'

    const onSubmit = async() => {
        if(textNota?.length > 5){
            setIsLoading(true);
            const form = {
                consultaId_nc: con?.id_con,
                cuerpo_nc: textNota,
            }
            if(filesCompress?.length > 0){
                form.filesCompress = filesCompress;
            }
            await ConsultasService.registerNota(form).then((resp)=>{
                setTextNota('');
                if(resp?.id_nc){
                    setIsLoading(false);
                    setFiles([]);
                    setFilesCompress([]);
                    if(actualizar){
                        actualizar();
                    }
                }
            }).catch(()=>{
                setIsLoading(false);
            })
        }else{
            toast.error('Debe ingresar la nota.', {
                position: "top-right",
                autoClose: 2500,
                pauseOnHover: false,
            });
            setIsLoading(false)
        }
    }
    const onDeletedFile = (fileDele) =>{
        const filesRest = files.filter((filI)=> filI.id !== fileDele?.id)
        const filesCompRest = filesCompress.filter((filI)=> filI.id !== fileDele?.id)
        setFiles(filesRest)
        setFilesCompress(filesCompRest)
    }
    const onCompressImg = async(imgx) =>{
        const nombreCortado = imgx?.name?.split('.');
        let extension = nombreCortado[ nombreCortado?.length - 1 ];
        extension = extension?.toLowerCase();
        if(extensionPermitida.includes( extension ) ){
            setIsLoading(true);
            const img = new Compressor(imgx, {
                quality: 0.3,
                convertSize:2000000,
                success(result) {
                    result.id=imgx?.id
                    result.preview=imgx?.preview
                    setIsLoading(false);
                    setFilesCompress(old => [...old,result]);
                }
            })
        }else{
            setFilesCompress(old => [...old,imgx]);
        }
    }
    useEffect(() => {
        if(files.length > 0){
            files.forEach((filImg)=>{
                const existe = filesCompress.find((flCompress)=>flCompress.id === filImg.id)
                if(!existe){
                    onCompressImg(filImg)
                }
            })
        }
    }, [files])
    const onRedirectConsulta = async() => {
        const form = {tipoMapa: 'radio'}
        if(con?.coordenadasConsulta_con?.length > 10){
            const coordenadasText = con?.coordenadasConsulta_con?.trim()?.replace(/\s+/g, ' ');
            const separados = coordenadasText.split(',');
            form.lat = separados[0];
            form.lng = separados[1];
        }
        if(con?.direccionConsulta_con?.trim()?.length > 3){
            const separador = con?.direccionConsulta_con?.trim()?.split('-');
            if(separador?.length === 2){
                form.barrio_t = separador[0]?.trim();
                form.calle_t = separador[1]?.trim();
            }else if(separador?.length > 2){
                form.barrio_t = separador[0]?.trim();
                form.calle_t = separador.slice(1).join(' - ');
            }else{
                form.calle_t = con?.direccionConsulta_con?.trim() || '';
            }
        }
        if(con?.planConsulta_con && arrPlanesFO.includes(con?.planConsulta_con?.toUpperCase())){
            form.tipoMapa = 'fibra'
        }
        if(con?.contactoCuenta_con){
            await ConsultasService.infoClienteConsulta({
                type: 'soft',
                idCliente: con?.contactoCuenta_con
            }).then((resp)=>{
                if(form?.lat){
                    resp.ubicacion.latitud = form.lat;
                    resp.ubicacion.longitud = form.lng;
                }
                if(con?.localidadId_con){
                    resp.lugarId_c = con?.localidadId_con;
                }
                if(con?.canal_con){
                    resp.canalcomu_c = con?.canal_con;
                }
                if(form?.barrio_t){
                    resp.barrio_t = form?.barrio_t;
                }
                if(form?.calle_t){
                    resp.calle_t = form?.calle_t;
                }
                if(con?.segmento_con){
                    resp.categoria.codigo = con?.segmento_con;
                }
                resp.tipoMapa = form.tipoMapa;
                navigate('/contactos/nuevonubi',{state:{item:resp, tipo: 'Contacto'}});
            })
        }else if(con?.contactoId_con){
            await ConsultasService.infoClienteConsulta({
                type: 'pot',
                idCliente: con?.contactoId_con
            }).then((potencial)=>{
                if(form?.lat){
                    potencial.latitud_c = form.lat;
                    potencial.longitud_c = form.lng;
                }
                if(con?.localidadId_con){
                    potencial.lugarId_c = con?.localidadId_con;
                }
                if(con?.canal_con){
                    potencial.canalcomu_c = con?.canal_con;
                }
                if(form?.barrio_t){
                    potencial.barrio_t = form?.barrio_t;
                }
                if(form?.calle_t){
                    potencial.calle_t = form?.calle_t;
                }
                if(con?.segmento_con){
                    potencial.categoria_cxe = con?.segmento_con;
                }
                potencial.tipoMapa = form.tipoMapa;
                navigate('/contactos/nuevonubi',{state:{item:potencial, tipo: 'Contacto'}});
            })
        }else{
            console.log('No existe')
        }
    }
    const onClickNewRecordatorio = () => {
        showModal(ModalCrearRecordatorio,{
            onGuardar: actualizarRecordatorios,
            nombreCompleto: con?.nombreContacto_con,
            contactoId: con?.contactoId_con,
            cuentaId: con?.contactoCuenta_con,
            servicioId: con?.cuentaServicioId_con,
            preServicioId: con?.preServicioId_con,
            consultaId: con?.id_con,
        });
    }
    const onClickViewRecordatorios = () => {
        showModal(ModalRecordatoriosConsulta,{ consulta: con});
    }
    return (
        <Grid item xs={12} key={con?.id_con}>
            <Card sx={{background: '#fff !important',borderRadius: 3, borderColor: '#6b788e', borderStyle: 'solid', borderWidth: 1}}>
                <Box sx={{background: '#e6f0ff',p:1, display: 'flex', justifyContent: 'space-between'}}>
                    <Typography sx={{color: '#15294b',fontWeight: 'bold', p: 1}} variant='h6'>
                        {`#${con?.id_con} - ${con?.nombreContacto_con}`}
                    </Typography>
                    <MenuOptionsConsulta
                        onRedirectConsulta={onRedirectConsulta}
                        onClickNewRecordatorio={onClickNewRecordatorio}
                        onClickViewRecordatorios={onClickViewRecordatorios}
                    />
                </Box>
                <Box p={2}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Motivo: 
                            <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.observaciones_con}</Box>
                        </Typography>
                        { con?.cuentaServicioId_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Servicio:
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{` #${con?.cuentaServicioId_con}`}</Box>
                            </Typography>
                        )}
                        <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Consulto por el plan: 
                            <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.planConsulta_con}</Box>
                        </Typography>
                        {con?.canal_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Canal de comunicacion: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.canalNombre}</Box>
                            </Typography>
                        )}
                        {con?.segmento_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Segmento: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.segmento_con}</Box>
                            </Typography>
                        )}
                        {con?.estadoConsulta_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Estado de la consulta: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.estadoConsulta_con}</Box>
                            </Typography>
                        )}
                        {con?.subEstadoConsulta_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Sub-Estado de la consulta: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.subEstadoConsulta_con}</Box>
                            </Typography>
                        )}
                        {con?.localidadId_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Localidad: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.localidadNombre}</Box>
                            </Typography>
                        )}
                        {con?.direccionConsulta_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Dirección: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.direccionConsulta_con}</Box>
                            </Typography>
                        )}
                        {con?.coordenadasConsulta_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Coordenadas: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{con?.coordenadasConsulta_con}</Box>
                            </Typography>
                        )}
                        {con?.bajadaMaxMb_con && (
                            <Typography sx={{color: '#15294b',fontWeight: 'bold',mb: 0.5}} component='div'>Cobertura de bajada: 
                                <Box sx={{ml:0.5,display: 'inline', color: '#6b788e',opacity: 0.9}}>{`${con?.bajadaMaxMb_con} Mb`}</Box>
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Avatar src={con?.autor?.foto_p} sx={{width:30, height:30,background: '#dfe2e6'}} />
                            <Typography sx={{color: '#8993a4',fontWeight: 'bold',mb: 0.5,ml: 1,mt:0.2}} component='div'>{con?.autor?.nombre_p}
                                <Box sx={{ml:0.5,display: 'inline', color: '#8993a4',opacity: 0.9,fontSize: 11}}>- Autor</Box>
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{color: '#15294b'}}>{FechaFunction.format(new Date(con?.createdAt),'dd/MM/yyyy HH:mm:ss')}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{background: '#b3b9c4'}} />
                <Collapse in={openComentario} timeout="auto" unmountOnExit>
                    <Box>
                        <Typography sx={{color: '#15294b', fontWeight: 'bold', ml: 2, fontSize: 16}}>
                            Comentarios
                        </Typography>
                        { (Array.isArray(con?.notas) && con?.notas?.length > 0) ? (
                            con.notas.map((nota)=>(
                                <Box sx={{padding: 2}}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Avatar sx={{width: 30, height: 30}} src={nota?.autor?.foto_p} />
                                        <Typography sx={{color: '#8993a4', fontWeight: 'bold', fontSize: 15, marginLeft: 1}}>
                                            {nota?.autor?.nombre_p || ''}
                                        </Typography>
                                        <Typography sx={{color: '#8993a4', fontWeight: 'bold', fontSize: 15, marginLeft: 1}}>
                                            |
                                        </Typography>
                                        <Typography sx={{color: '#15294b', fontWeight: 'normal', fontSize: 14, marginLeft: 1}}>
                                            {FechaFunction.format(new Date(nota?.createdAt),'dd/MM/yyyy HH:mm:ss')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            background: nota?.img?.length > 0 ? '#e6f0ff' : '#e6f0ff',
                                            marginTop: 0.5,
                                            borderTopRightRadius: 10,
                                            borderBottomRightRadius: 10,
                                            borderBottomLeftRadius: 10,
                                            padding: nota?.img?.length > 0 ? 1 : 0
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: {xs: '100%',md: '90%', lg: '98%'},
                                                marginTop: 0,
                                                borderWidth: 0,
                                                '& .MuiInputLabel-root':{
                                                    color: '#15294b',
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#e6f0ff',
                                                        borderWidth: 0,
                                                        borderTopRightRadius: 10,
                                                        borderBottomRightRadius: 10,
                                                        borderBottomLeftRadius: 10,
                                                        borderWidth: 0
                                                    },
                                                    '&.Mui-focused textarea': {
                                                        borderColor: '#e6f0ff',
                                                    },
                                                    '& textarea': {
                                                        color: '#15294b',
                                                        fontWeight: 'bold',
                                                        padding: '10px',
                                                        background: '#e6f0ff',
                                                        borderTopRightRadius: 10,
                                                        borderBottomRightRadius: 10,
                                                        borderBottomLeftRadius: 10,
                                                        borderWidth: 0,
                                                        marginTop: -2
                                                    },
                                                    '& textarea::placeholder': {
                                                        color: '#6b788e',
                                                        fontWeight: 'normal',
                                                        opacity: 1,
                                                        borderWidth: 0
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#e6f0ff',
                                                    },
                                                },
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            multiline
                                            value={nota?.cuerpo_nc || ''}
                                        />
                                        {nota?.img?.length > 0 && (
                                            <Grid container sx={{display: 'flex', flexDirection: 'row'}}>
                                                { nota?.img?.map((img)=>{
                                                    const tipe = img.path_inc.split('.');
                                                    if(tipe[tipe?.length - 1] === 'jpg' || tipe[tipe?.length - 1] === 'png' || tipe[tipe?.length - 1] === 'jpeg' || tipe[tipe?.length - 1] === 'svg' || tipe[tipe?.length - 1] === 'x-png'){
                                                        return (
                                                            <Grid item xs={6} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                                                <PhotoConsumer
                                                                    key={`IMG${img.path_inc}`}
                                                                    src={`${urlNubi}${img.path_inc}&token=${token}`}
                                                                    intro={img.path_inc}
                                                                >
                                                                    <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                                                        <img
                                                                            src={`${urlNubi}${img.path_inc}&token=${token}`}
                                                                            alt={`${urlNubi}${img.path_inc}&token=${token}`}
                                                                            style={{width:50,height:50}}
                                                                        />
                                                                        <Typography className="text-center text-gray-600 ml-1" style={{fontSize: 12, opacity:0.9, textOverflow: 'ellipsis'}}>
                                                                            {img?.nombre_inc}
                                                                        </Typography>
                                                                    </Card>
                                                                </PhotoConsumer>
                                                            </Grid>
                                                        )
                                                    }else if(tipe[tipe?.length - 1] === 'wmv' || tipe[tipe?.length - 1] === 'mp4' || tipe[tipe?.length - 1] === 'avi' || tipe[tipe?.length - 1] === 'mov'){
                                                        return(
                                                            <Grid item xs={12} sm={6} md={4} lg={4} className='ml-2 mt-1 cursor-pointer'>
                                                                <Card sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}>
                                                                    <Box>
                                                                        <ReactPlayer
                                                                            key={`Video${img.path_inc}`}
                                                                            url={`${urlNubi}${img.path_inc}&token=${token}`}
                                                                            width='155px'
                                                                            height='60px'
                                                                            controls
                                                                        />
                                                                    </Box>
                                                                    <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                                        {img?.nombre_inc}
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    }else if(tipe[tipe?.length - 1] === 'pdf'){
                                                        return(
                                                            <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                                                <Card
                                                                    sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                                                    aria-hidden="true"
                                                                    onClick={()=>saveAs(`${urlNubi}${img.path_inc}&token=${token}`,img.path_inc)}
                                                                >
                                                                    <img
                                                                        src='/img/pdf.png'
                                                                        alt='pdf.png'
                                                                        style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                                                    />
                                                                    <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                                        {img?.nombre_inc}
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    }else if(tipe[tipe?.length - 1] === 'doc' || tipe[tipe?.length - 1] === 'docx'){
                                                        return(
                                                            <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                                                <Card
                                                                    sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                                                    aria-hidden="true"
                                                                    onClick={()=>saveAs(`${urlNubi}${img.path_inc}&token=${token}`,img.path_inc)}
                                                                >
                                                                    <img
                                                                        src='/img/doc.png'
                                                                        alt='doc.png'
                                                                        style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                                                    />
                                                                    <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                                        {img?.nombre_inc}
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    }else if(tipe[tipe?.length - 1] === 'xls' || tipe[tipe?.length - 1] === 'xlsx'){
                                                        return(
                                                            <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                                                <Card
                                                                    sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                                                    aria-hidden="true"
                                                                    onClick={()=>saveAs(`${urlNubi}${img.path_inc}&token=${token}`,img.path_inc)}
                                                                >
                                                                    <img
                                                                        src='/img/xls.png'
                                                                        alt='xls.png'
                                                                        style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                                                    />
                                                                    <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                                        {img?.nombre_inc}
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    }else if(tipe[tipe?.length - 1] === 'txt'){
                                                        return(
                                                            <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                                                <Card
                                                                    sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                                                    aria-hidden="true"
                                                                    onClick={()=>saveAs(`${urlNubi}${img.path_inc}&token=${token}`,img.path_inc)}
                                                                >
                                                                    <img
                                                                        src='/img/txt.png'
                                                                        alt='txt.png'
                                                                        style={{width:55,height:55, borderRadius:50,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 55%)'}}
                                                                    />
                                                                    <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                                        {img?.nombre_inc}
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    }else{
                                                        return(
                                                            <Grid item xs={12} sm={6} md={4} lg={3} className='ml-2 mt-1 cursor-pointer'>
                                                                <Card
                                                                    sx={{ background: '#fff !important' ,display: 'flex', alignItems: 'center',p:1,borderRadius:4,boxShadow:3}}
                                                                    aria-hidden="true"
                                                                    onClick={()=>saveAs(`${urlNubi}${img.path_inc}&token=${token}`,img.path_inc)}
                                                                >
                                                                    <img
                                                                        src='/img/documentAll.png'
                                                                        alt='documentAll.png'
                                                                        style={{width:55,height:55, borderRadius:10,boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 15%)'}}
                                                                    />
                                                                    <Typography className="text-center text-gray-600 ml-1" sx={{fontSize: 12, opacity:0.9,textOverflow: 'ellipsis'}}>
                                                                        {img?.nombre_inc}
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    }
                                                })}
                                            </Grid>
                                        )}
                                    </Box>
                                </Box>
                            ))
                        ):(
                            <Typography sx={{color: '#15294b', fontWeight: 'normal',textAlign: 'center', fontSize: 15}}>
                                Aún no hay comentarios
                            </Typography>
                        )}
                        <Box sx={{p: 2}}>
                            <Card sx={{background: '#fff !important', boxShadow: 4, p: 1, borderRadius: 4,width: '100%'}}>
                                    { filesCompress?.length > 0 &&(
                                        <Grid container mt={1}>
                                            {filesCompress.map((fil)=>(
                                                <Grid item xs={12} sm={5.8} md={3.8} lg={3.8} ml={1} mt={{xs:1,lg:0}}>
                                                    <CardUploadFile item={fil} key={fil?.id} onDeleted={(e)=>onDeletedFile(e)} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginTop: filesCompress?.length > 0 ? 1 : 0
                                    }}
                                >
                                    <Avatar sx={{width: 30, height: 30}} src={user?.foto_p} />
                                    <Typography sx={{color: '#8993a4', fontWeight: 'bold', fontSize: 15, marginLeft: 1}}>
                                        {user?.nombre_p || ''}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            background: '#f5f9f7',
                                            borderTopRightRadius: 20,
                                            borderBottomRightRadius: 20,
                                            borderBottomLeftRadius: 20,
                                            marginTop: 1,
                                            width: '100%'
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                width: {xs: '70%',md: '90%', lg: '92%'},
                                                '& .MuiInputLabel-root':{
                                                    color: '#15294b',
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    marginTop: 1
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#f5f6f7',
                                                        borderWidth: 0
                                                    },
                                                    '&.Mui-focused textarea': {
                                                        borderColor: '#f5f6f7',
                                                    },
                                                    '& textarea': {
                                                        color: '#15294b',
                                                        fontWeight: 'bold',
                                                        padding: '0px',
                                                        background: '#f5f6f7',
                                                    },
                                                    '& textarea::placeholder': {
                                                        color: '#6b788e',
                                                        fontWeight: 'normal',
                                                        opacity: 1,
                                                    },
                                                },
                                            }}
                                            multiline
                                            placeholder="Escribir comentario"
                                            value={textNota}
                                            onChange={(e)=>setTextNota(e.target.value)}
                                        />
                                        <Box
                                            sx={{
                                                background: '#f5f6f7',
                                                width: 35,
                                                height: 35,
                                                position: 'absolute',
                                                top: 8,
                                                right: 12,
                                                borderRadius: 50
                                            }}
                                        >
                                            <InputFileNotaConsulta
                                                id={`otConsulta${con?.id_con}`}
                                                onChange={(e)=>setFiles((old)=>[...old,...e])}
                                                setFoto={setFiles}
                                                accept={acceptInputFile}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{width: '1%'}} />
                                    <IconButton
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            background: '#0364ff',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            '&:hover':{
                                                background: '#5697ff'
                                            }
                                        }}
                                        onClick={()=>onSubmit()}
                                    >
                                        <SendOutlinedIcon
                                            color="primary"
                                            sx={{
                                                fontSize: 25,
                                                color: '#fff',
                                                ml: 0.5,
                                                mt: -0.3,
                                                transform: 'rotate(-45deg)',
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                </Collapse>
                <Box sx={{display: 'flex', justifyContent: 'space-between',p: 1, alignItems: 'center'}}>
                    <Typography sx={{color: '#15294b', fontWeight: 'bold', ml: 2}}>
                        {openComentario ? 'Ocultar comentarios' : 'Ver comentarios'}
                    </Typography>
                    <IconButton
                        sx={{
                            p: 0.5,
                            borderRadius: 0,
                            mr: 2,
                            transform: openComentario ? 'rotate(90deg)' : 'rotate(-93deg)'
                        }}
                        onClick={()=>handleClickComentarios()}
                    >
                        <ArrowBackIosNewIcon sx={{color: '#15294b', fontSize: 22}} />
                    </IconButton>
                </Box>
            </Card>
        </Grid>
    )
}

export default CardConsultaV2