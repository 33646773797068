import axios from 'axios';
import store from '../store/index';
import {logout} from '../store/actions/authActions';
import {toast} from 'react-toastify';

axios.defaults.baseURL = "https://zeta.strongsystems.com.ar:8000/api";

const {token, tokenNubi,tokenStr} = store.getState().auth;
/* axios.defaults.headers.common = {
    "Accept": "application/json",
    "nun-otoken": token,
    "nubi-token": tokenNubi,
    "str-token": tokenStr
}; */

function closeModals(){
    const {stack} = store.getState().modalProvider;
    if(stack){
        stack.forEach(({id})=>{
            store.dispatch({
                type: '@react-redux-modal-provider.hide',
                id,
            })
        })
    }
}

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        const tokenNubi = localStorage.getItem('tokenNubi');
        const tokenStr = localStorage.getItem('tokenStr');

        config.headers["Accept"] = "application/json";
        if (token) config.headers["nun-otoken"] = token;
        if (tokenNubi) config.headers["nubi-token"] = tokenNubi;
        if (tokenStr) config.headers["str-token"] = tokenStr;

        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use((response) => {
    if(response?.config?.responseType === "blob"){
        return response;
    }
    return response.data;
}, (error) => {
    if(!error.response){
        return false;
    }
    if (error && error.response.status === 401) {
        toast.error(error.response?.data?.msg || error.response?.data?.error || 'Ocurrío un problema.',{
            autoClose: 4000,
            hideProgressBar: false,
        });
        closeModals();
        if(token){
            window.location.replace('/login')
            store.dispatch(logout());
        }
    }
    if (error.response.status === 406 ) {
        toast.error(error.response?.data?.msg || error.response?.data?.error || 'Ocurrío un problema.',{
            autoClose: 4000,
            hideProgressBar: false,
        });
        closeModals();
        if(token){
            window.location.replace('/login')
            store.dispatch(logout());
        }
    }
    // Ejemplo: Quiere acceder a un ticket que es Interno
    if (error.response.status === 403 ) {
        toast.error(error.response?.data?.msg || error.response?.data?.error || 'No Autorizado.',{
            autoClose: 4000,
            hideProgressBar: false,
        });
    }
    if (error.response.status === 422 || error.response.status === 400 ) {
        toast.error(error.response?.data?.msg || error.response?.data?.error ||error.response?.data?.errors[0].msg || 'Ocurrío un problema.',{
            autoClose: 4000,
            hideProgressBar: false,
        });
        return Promise.reject(error.response.data);
    }
    if (error.response.status === 404) {
        toast.error(error.response?.data?.msg || error.response?.data?.error ||error.response?.data?.errors[0].msg || 'Ocurrío un problema.',{
            autoClose: 4000,
            hideProgressBar: false,
        });
        return Promise.reject(error.response.data);
    }
    if (error.response.status === 500 ){
        toast.error('Ocurrío un problema. Comuniquese con el Administrador del sistema.',{
            autoClose: 4000,
        });
    }
    return Promise.reject(error);
});
