import {parseISO} from 'date-fns';
import { saveAs } from 'file-saver';
import colorLib from '@kurkle/color';

function toIntegerNumber(string,response = null){
    const parse = Number(string);
    if(Number.isNaN(parse)){
        return response;
    }
    return parse;
}

function isNormalInteger(str) {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

function isObject(object){
    return typeof object === 'object' && object !== null;
}

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

function getContrastYIQ(hexcolor) {
    if (hexcolor == null) {
        return 'black';
    }
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
}

export function getTransparent(value, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
}

function getTareaEstadoBadge(estado) {
    let response = '';
    switch (estado) {
        case 1:
            /*
            EN ESPERA
            */
            response = `<span class="badge badge-pill bg-primary">EN ESPERA</span>`;
            break;
        case 2:
            /*
            A DESIGNAR
            */
            response = `<span class="badge badge-pill bg-secondary">A DESIGNAR</span>`;
            break;
        case 3:
            /*
            ASIGNADO
            */
            response = `<span class="badge badge-pill bg-success">ASIGNADO</span>`;
            break;
        case 4:
            /*
            REPROGRAMADO
            */
            response = `<span class="badge badge-pill bg-warning text-dark">REPROGRAMADO</span>`;
            break;
        case 5:
            /*
            VENCIDO
            */
            response = `<span class="badge badge-pill bg-warning text-dark">VENCIDO</span>`;
            break;
        case 6:
            /*
            ELIMINADO
            */
            response = `<span class="badge badge-pill bg-danger">ELIMINADO</span>`;
            break;
        case 7:
            /*
            PARA REPROGRAMAR
            */
            response = `<span class="badge badge-pill bg-warning text-dark">PARA REPROGRAMAR</span>`;
            break;
        default:
            break;
    }
    return response;
}

function setItem(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
}

function getItem(key, defaultReturn = null) {
    let item;
    try {
        item = JSON.parse(localStorage.getItem(key));
        if (item === null) {
            item = defaultReturn;
        }
    } catch (e) {
        item = defaultReturn;
    }
    return item;
}

function removeItem(key) {
    localStorage.setItem(key);
}

function validaDateString(value) {
    let response;
    if (typeof value === 'string') {
        response = parseISO(value);
    } else {
        response = value;
    }
    return response;
}

function primeraLetraNombre(nombre){
    if(nombre == null){
        return "";
    }
    return nombre.split(/\s/).reduce((response,word) => {
        return response+word.slice(0,1)
    },'');
}

function extractNumber(string){
    if(String(string).length === 0 || string === null){
        return '';
    }
    const regex = /\d+/g;
    return regex.exec(string).join('');
}

function extractErrors({errors},setError){
    if(errors){
        Object.keys(errors).forEach((key)=>{
            if(errors[key]){
                setError(key, {
                    type: "manual",
                    message: errors[key][0],
                });
            }
        });
    }
}

function jsonToFormData(data) {
    const formData = new FormData()
    const entries = Object.entries(data)
    for (let i = 0; i < entries.length; i+=1) {
        const arKey = entries[i][0]
        let arVal = entries[i][1]
        if (typeof arVal === 'boolean') {
            arVal = arVal === true ? 1 : 0
        }
        if (Array.isArray(arVal)) {
            if (this.isFile(arVal[0])) {
                for (let z = 0; z < arVal.length; z+=1) {
                    formData.append(`${arKey}[]`, arVal[z])
                }
                // eslint-disable-next-line no-continue
                continue;
            } else if (arVal[0] instanceof Object) {
                for (let j = 0; j < arVal.length; j+=1) {
                    if (arVal[j] instanceof Object) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const prop in arVal[j]) {
                        if (Object.prototype.hasOwnProperty.call(arVal[j], prop)) {
                        // eslint-disable-next-line no-restricted-globals
                        if (!isNaN(Date.parse(arVal[j][prop]))) {
                            formData.append(
                            `${arKey}[${j}][${prop}]`,
                            new Date(arVal[j][prop])
                            )
                        } else {
                            formData.append(`${arKey}[${j}][${prop}]`, arVal[j][prop])
                        }
                        }
                    }
                    }
                }
                // eslint-disable-next-line no-continue
                continue;
            } else {
                arVal = JSON.stringify(arVal)
            }
        }

        if (arVal === null) {
            // eslint-disable-next-line no-continue
            continue;
        }
        formData.append(arKey, arVal)
    }
    return formData
}

function formatMatricula(value){
    let rsp = '';
    if(value && value.length>6){
        rsp = value.replace(/(\w{2})(\w{3})(\w{2})/, '$1 $2 $3');
    } else if(value && value.length === 6) {
        rsp = value.replace(/(\w{3})(\w{3})/, '$1 $2');
    }
    return rsp;
}

function downloadFile(promise,fileName= 'Dowloader'){
    return promise.then((response)=>{
        saveAs(response.data, fileName);
    })
}

function formatQueryWithSearch(query){
    const params = new URLSearchParams();
    if(query){
        Object.keys(query).forEach(key=>{
            if(key === 'search' && Array.isArray(query[key])){
                query[key].forEach(opt=>{
                    params.set(`search[${opt.id}]`,opt.value);
                });
            } else if(typeof query[key] !== "undefined"){
                params.set(key,query[key]);
            }
        })
    }
    return params;
}

function generateUUID(digits) {
    const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
    const uuid = [];
    let i = 0
    for (i ; i < digits; i+=1 ) {
        uuid.push(str[Math.floor(Math.random() * str.length)]);
    }
    return uuid.join('');
}

function isValidCoordinates(coordinates) {
    /* eslint-disable no-useless-escape */
    if((!coordinates.match(/^[-]?\d+[\.]?\d*, [-]?\d+[\.]?\d*$/) && !coordinates.match(/^[-]?\d+[\.]?\d*,[-]?\d+[\.]?\d*$/))) {
        return false;
    }
    const [latitude, longitude]=coordinates.split(",");
    return (latitude>-90 && latitude<90 && longitude>-180 && longitude<180);
}

function extraerDataContratoSonft(contrato) {
    let data = {}
    if(contrato.length > 0){
        contrato.forEach((tr)=>{
            if(tr){
                if(Object.keys(tr).length > 0 ){
                    Object.entries(tr).forEach((([keys,value])=>{
                        data = {
                            nombre: value?.cliente || value[0]?.descripcion,
                            nombreServicio: value?.nombre || value[0]?.nombre || value[0]?.nombreServicio,
                            cuenta: value?.cuenta || value[0]?.cuenta,
                            email: value?.email || value[0]?.email,
                            telefono: value?.telefono || value[0]?.telefono,
                            telefono2: value?.telefono2 || value[0]?.telefono2,
                            domicilio: value?.direccion_servicio || value[0]?.ubicacion?.domicilio,
                            servicioId: value?.contrato || value[0]?.numero || keys,
                            tipo: value?.segmento || value[0]?.tipo,
                            localidad: value?.codpostal || value?.ubicacion?.cod_postal || value[0]?.ubicacion?.cod_postal
                        }
                        if(value?.ubicacion?.localidad){
                            data.localidad = `${data.localidad} - ${value?.ubicacion?.localidad}`
                        }
                        if(value[0]?.localidad?.nombre){
                            data.localidadCalendario = `${value[0]?.localidad?.codLocalidad} - ${value[0]?.localidad?.nombre}`
                        }else if(value?.localidad?.nombre){
                            data.localidadCalendario = `${value?.localidad?.codLocalidad} - ${value?.localidad?.nombre}`
                        }
                        if(value[0]?.ubicacion?.cod_postal && value[0]?.ubicacion?.nomLocalidad){
                            data.localidad = `${value[0]?.ubicacion?.cod_postal} - ${value[0]?.ubicacion?.nomLocalidad}`
                        }else if(value?.codpostal && value?.nomLocalidad){
                            data.localidad = `${value?.codpostal} - ${value?.nomLocalidad}`
                        }
                        if(value[0]?.ubicacion?.latitud && value[0]?.ubicacion?.longitud){
                            data.coordenadas = `${value[0]?.ubicacion?.latitud}, ${value[0]?.ubicacion?.longitud}`
                        }else if(value?.latitud && value?.longitud){
                            data.coordenadas = `${value?.latitud}, ${value?.longitud}`
                        }
                        if(value[0]?.vip || value?.vip){
                            data.vip = value[0]?.vip || value?.vip
                        }
                    }))
                }
            }
        })
        return data
    }
    return data
}

function formatoNumberBonificacion(numero) {
    const strNumber = (numero / 100)?.toString();
    switch (strNumber?.length) {
        case 1:
            return `${strNumber}.0000`
        case 3:
            return `${strNumber}000`
        case 4:
            return `${strNumber}00`
        default:
            return `1.0000`
    }
}

const AuxiliarFunction = {
    isObject,
    isFunction,
    isNormalInteger,
    toIntegerNumber,
    formatQueryWithSearch,
    getContrastYIQ,
    getTareaEstadoBadge,
    getTransparent,
    setItem,
    getItem,
    removeItem,
    validaDateString,
    primeraLetraNombre,
    extractNumber,
    extractErrors,
    jsonToFormData,
    downloadFile,
    formatMatricula,
    generateUUID,
    isValidCoordinates,
    extraerDataContratoSonft,
    formatoNumberBonificacion
};
export default AuxiliarFunction;
